/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import '@ionic/angular/css/ionic-swiper';
.logout{
    margin-top: 35px; 
    margin-bottom: 35px;
    color: var(--ion-color-step-350);
  }
  
  .avatar, .usericon-mainmenu{
    margin: auto;
    color: var(--ion-color-step-350);
    font-size:70px;
    margin-bottom:15px;
  }
  
  .forgotPassword {
      text-align: center;
      margin: -12px 0 15px 0;
      float: left;
      width: 100%;
    }
    
    .forgotPassword span {
      color: var(--ion-color-primary);
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      display: inline-block;
      padding-top: 20px;
    }
  
    .redirectToLogin {
      cursor: pointer;
      padding: 15px 0 0;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      display: block;
      color: var(--ion-color-primary-contrast);
    }
    
    .redirectToLogin .redirect {
      cursor: pointer;
      color: #ffffff;
      text-decoration: underline;
    }
    
  
  .or {
      text-align: center;
      display: block;
      color: var(--ion-color-medium);
      background: var(--ion-background-color);
      position: relative;
      margin: 5px 0 0px;
    }
    
    .orInner {
      background: var(--ion-background-color);
      display: inline-block;
      z-index: 4;
      position: relative;
      padding: 0 12px;
    }
    
    .or:before {
      position: absolute;
      content: '';
      left: 0;
      top: 11px;
      width: 100%;
      height: 1px;
      background: var(--ion-color-medium);
    }
  
  .icon-bewilligungspflichtig{
    margin:0px; 
    padding:0px; 
    color: var(--ion-color-warning);
    
    font-size:12px;  
    
    
  }
  
  .infobox{
    //background-color: var(--ion-color-warning);
    border:2px solid var(--ion-color-step-950);
    color: var(--ion-color-step-950);
    border-radius: 5px;
  }
  .infoicon{
  
  }

  .pb-tag {
    background-color: #1f3d8b;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;
    opacity: 0.9;
    border: 3px solid #1f3d8b;
    }  

  .pb-tag:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #1f3d8b;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%);
    width: 0;
    opacity: 0.9;
    }

    .pb-tag-done {
      background-color: var(--ion-color-success);
      border-radius: 8px;
      color: #fff;
      font-size: 14px;
      padding: 10px 15px;
      position: relative;
      opacity: 0.9;
      border: 3px solid var(--ion-color-success);
      }  
  
    .pb-tag-done:after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--ion-color-success);
      content: "";
      height: 0;
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translate(-50%);
      width: 0;
      opacity: 0.9;
      }
  